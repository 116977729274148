import React from "react"
import Layout from '../components/layout'
import Head from '../components/head'

const IndexPage = () => {
    return (
        <Layout> 
            <Head title="Home"/>
            <h1>Hello.</h1>
            <h2>I'm Gustaf, a software engineer living in Stockholm.</h2>
            <h4>This site is under development, but feel free to look around.</h4>
            <p>If you are interested, the site is running on React, Gatsby, GraphQL and Contentful, hosted on Netlify.</p>
        </Layout>
    )
}

export default IndexPage;
